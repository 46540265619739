angular.module('CaresApp').controller(
  'AddBillableItemTypeStandardRateDialogController', [
    '$mdDialog',
    '$mdToast',
    'BillableItemTypeStandardRateService',
    'OcrUtils',
    function($mdDialog, $mdToast, BillableItemTypeStandardRateService, OcrUtils) {
      const self = this;

      self.$onInit = function() {
        self.standardRate = {
          billableItemTypeId: self.billableItemType.id,
        };

        self.currentRate = undefined;
        for (const rate of self.standardRates) {
          if (!rate.endDate) {
            self.currentRate = rate;
            self.minDate = new Date(rate.startDate);
            break;
          }
        }
      }

      self.updateRate = function() {
        if (self.rateValue === null) {
          self.standardRate.standardRateMillis = null;
        } else {
          let { rateValue } = self
          const rateValueDotIdx = rateValue.indexOf('.')
          if (rateValueDotIdx === -1) {
            rateValue += '000'
          } else {
            const dollars = rateValue.substring(0, rateValueDotIdx)
            let centsString = rateValue.substring(rateValueDotIdx + 1)
            if (centsString.length > 3) {
              centsString = centsString.substr(0, 3)
            } else {
              while (centsString.length < 3) {
                centsString += "0"
              }
            }
            rateValue = dollars + centsString
          }
          self.standardRate.standardRateMillis = parseInt(rateValue);
        }
      };

      self.hide = $mdDialog.hide;
      self.cancel = $mdDialog.cancel;

      self.submit = function() {
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving new standard rate...')
            .position("bottom right"),
        );

        BillableItemTypeStandardRateService.save(self.standardRate).$promise
          .then(function() {
            $mdToast.show(
              $mdToast.simple()
                .textContent('New standard rate saved.')
                .position("bottom right"),
            );

            if (self.currentRate) {
              self.currentRate.endDate = self.standardRate.startDate;
            }
            self.standardRates.push(self.standardRate);

            $mdDialog.hide();
          })
          .catch(function(error) {
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          });
      };
    },
  ]);
