angular.module('CaresApp').controller('AbaHistoryDialogController', [
  '$scope',
  '$mdDialog',
  'appointment',
  'OcrUtils',
  'officeUsers',
  'AppointmentBillingAllocationHistoryService',
  function($scope, $mdDialog, appointment, OcrUtils, officeUsers,
    AppointmentBillingAllocationHistoryService,
  ) {
    $scope.appointment = appointment;
    $scope.officeUsers = officeUsers;
    $scope.loaded = true;

    if (angular.isUndefined($scope.appointment.abaHistory) && $scope.appointment.id) {
      $scope.loaded = false;
      AppointmentBillingAllocationHistoryService.get({
        appointment_id: $scope.appointment.id,
      }).$promise.then(
        function(response) {
          $scope.appointment.abaHistory = response.list;
          $scope.loaded = true;
        },
      )
    }

    $scope.getOfficeUser = function(user, userId) {
      if (userId === 0) {
        return "System";
      }
      return OcrUtils.getUserFullName(user);
    };

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
  },
]);
