angular.module('CaresApp').controller('CloseAppointmentDialogController', [
  '$scope',
  '$mdDialog',
  function($scope, $mdDialog) {
    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.submit = () => $mdDialog.hide(true);
  },
]);

