angular.module('CaresApp').controller('InvoiceAuditLogController', [
  '$scope',
  '$mdDialog',
  'invoice',
  'invoiceNumber',
  'InvoiceAuditLogService',
  function($scope, $mdDialog, invoice, invoiceNumber, InvoiceAuditLogService) {
    $scope.invoice = invoice;
    $scope.invoiceNumber = invoiceNumber;
    $scope.loaded = true;

    if (angular.isUndefined($scope.invoice.auditLog) && $scope.invoiceNumber) {
      $scope.loaded = false;
      InvoiceAuditLogService.get({
        invoiceNumber: $scope.invoiceNumber,
      }).$promise.then(
        function(response) {
          $scope.invoice.auditLog = response.list;
          $scope.loaded = true;
        },
      )
    }
    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
  },
]);
