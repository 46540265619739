angular.module('CaresApp').controller('AddVendorDialogController', [
  '$scope', '$mdDialog', 'vendor',
  'OcrUtils', 'OcrLookups', 'latestInvoiceDate',
  function($scope, $mdDialog, vendor, OcrUtils, OcrLookups, latestInvoiceDate) {
    $scope.vendor = Object.assign({}, vendor);

    if (latestInvoiceDate) {
      const msec = Date.parse(latestInvoiceDate);
      $scope.latestInvoiceDate = new Date(msec);
    }

    $scope.states = OcrLookups.dummyList;
    OcrLookups.get('State').then((states) => $scope.states = states);

    $scope.today = new Date();
    $scope.minEndDate = new Date();

    $scope.submit = () => $mdDialog.hide($scope.vendor);

    $scope.delete = () => $mdDialog.hide(undefined);
    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    if ($scope.vendor.startDate) {
      $scope.vendor.startDate = new Date(moment($scope.vendor.startDate).format());
      if (!$scope.latestInvoiceDate) {
        $scope.minEndDate = new Date($scope.vendor.startDate);
      } else if ($scope.vendor.startDate > $scope.latestInvoiceDate) {
        $scope.minEndDate = new Date($scope.vendor.startDate);
      } else {
        $scope.minEndDate = $scope.latestInvoiceDate;
      }
      $scope.minEndDate.setDate($scope.minEndDate.getDate());
    }
    if ($scope.vendor.endDate) {
      $scope.vendor.endDate = new Date(moment($scope.vendor.endDate).format());
    }

    $scope.getMinStartDate = () => new Date(latestInvoiceDate);

    $scope.setMinEndDate = function(date) {
      if (!$scope.latestInvoiceDate) {
        $scope.minEndDate = new Date(date);
      } else if (new Date(date) > $scope.latestInvoiceDate) {
        $scope.minEndDate = new Date(date);
      } else {
        $scope.minEndDate = $scope.latestInvoiceDate;
      }
      $scope.minEndDate.setDate($scope.minEndDate.getDate());
    }

    $scope.validateLength = function() {
      if (angular.isDefined($scope.vendor.phone) && $scope.vendor.phone !== null) {
        if ($scope.vendor.phone.toString().length !== 10) {
          $scope.addvendorform.phoneNumber.$setValidity("length", false);
        } else {
          $scope.addvendorform.phoneNumber.$setValidity("length", true);
        }
      } else {
        $scope.addvendorform.phoneNumber.$setValidity("required", false);
        $scope.addvendorform.phoneNumber.$setTouched();
      }
    };

    $scope.$watch(
      () => angular.isDefined($scope.addvendorform),
      function() {
        $scope.$watch(
          () => $scope.addvendorform.$invalid === true,
          function() {
            angular.forEach($scope.addvendorform.$error, function(field) {
              angular.forEach(field, function(errorField) {
                errorField.$setTouched();
              });
            });
            $scope.validateLength();
          }, true);
      }, true);

  },
]);
