angular.module('CaresApp').controller('AddOfficeDialogController', [
  '$scope',
  '$mdDialog',
  'OcrUtils',
  'earliestDate',
  function($scope, $mdDialog, OcrUtils, earliestDate) {
    $scope.today = OcrUtils.today;
    $scope.earliestDate = new Date(earliestDate);

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = () => $mdDialog.hide($scope.startDate);

    $scope.$watch(
      () => angular.isDefined($scope.addOfficeForm),
      function() {
        $scope.$watch(
          () => $scope.addOfficeForm.$invalid === true,
          function() {
            angular.forEach($scope.addOfficeForm.$error, function(field) {
              angular.forEach(field, function(errorField) {
                errorField.$setTouched();
              });
            });
          }, true);
      }, true);
  },
]);
