angular.module('CaresApp').controller('ExcessFeesRequestDialogController', [
  '$scope',
  '$mdDialog',
  'OcrAuth',
  'appointment',
  function($scope, $mdDialog, OcrAuth, appointment) {
    $scope.appointment = appointment;

    $scope.request = {
      abbreviatedCaseNumber: $scope.appointment.abbreviatedCaseNumber,
      appointmentId: $scope.appointment.id,
      excessFeesRequestStatusId: 1,
      userSubmittedId: OcrAuth.session.user.id,
      timestamp: new Date(),
      totalPaidCents: $scope.appointment.financialProfile.paidCents / 100.00,
      remainingAmountCents: (appointment.appointmentBillingAllocationCents
        - appointment.financialProfile.enteredNotInvoicedCents
        - appointment.financialProfile.invoicedNotPaidCents
        - appointment.financialProfile.paidCents) / 100.00,
      currentAllocationCents: $scope.appointment.appointmentBillingAllocationCents / 100.00,
      requestedAllocationCents: $scope.appointment.appointmentBillingAllocationCents / 100.00,
      judicialDistrictId: $scope.appointment.judicialDistrictId,
      caseTypeId: $scope.appointment.caseTypeId,
      officeId: $scope.appointment.officeId,
    }

    $scope.confirm = false;
    $scope.submitting = false;

    $scope.updateTotal = function() {
      const parsedAdditional = parseInt($scope.request.additionalFundsCents);
      if (parsedAdditional <= 0) {
        $scope.excessfeesrequestform.additionalFunds.$setValidity("min", false);
      } else {
        $scope.excessfeesrequestform.additionalFunds.$setValidity("min", true);
      }
      $scope.request.requestedAllocationCents
        = parseInt($scope.request.currentAllocationCents) + parsedAdditional;
    }

    $scope.disableSubmit = function() {
      if ($scope.submitting) {
        return true;
      }

      if (!$scope.excessfeesrequestform.$invalid && $scope.confirm) {
        return false;
      }
      return true;
    }

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.submit = function() {
      $scope.submitting = true;

      const requestCopy = angular.copy($scope.request);

      requestCopy.timestamp = new Date();
      requestCopy.totalPaidCents = parseInt($scope.request.totalPaidCents) * 100;
      requestCopy.remainingAmountCents = parseInt($scope.request.remainingAmountCents) * 100;
      requestCopy.currentAllocationCents = parseInt($scope.request.currentAllocationCents) * 100;
      requestCopy.additionalFundsCents = parseInt($scope.request.additionalFundsCents) * 100;
      requestCopy.requestedAllocationCents =
        parseInt($scope.request.requestedAllocationCents) * 100;
      requestCopy.initialAdditionalFundsCents = requestCopy.additionalFundsCents;

      $scope.submitting = false;
      $mdDialog.hide(requestCopy);
    }

    $scope.$watch(
      () => angular.isDefined($scope.excessfeesrequestform),
      function() {
        $scope.excessfeesrequestform.additionalFunds.$setTouched();
        $scope.excessfeesrequestform.explanation.$setTouched();
      }, true);
  },
]);
