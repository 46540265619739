angular.module('CaresApp').controller('CreateInvoiceDialogController', [
  '$mdDialog',
  'OcrUtils',
  'InvoiceService',
  '$interval',
  function($mdDialog, OcrUtils, InvoiceService, $interval) {
    const self = this;

    self.saving = false;
    self.today = OcrUtils.today;

    self.invoice = {
      rangeFrom: OcrUtils.firstDayOfPreviousMonth(),
      rangeTo: OcrUtils.lastDayOfPreviousMonth(),
    };

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      self.saving = true;
      self.invoice.acceptOverlap = self.overlapping;
      InvoiceService.save(self.invoice).$promise.then(
        function(resp) {
          if (resp.count === 1) {
            Object.assign(self.invoice, resp.created[0]);
            const ip = $interval(function() {
              InvoiceService.get({ id: self.invoice.invoiceNumber }).$promise
                .then(function() {
                  if (resp.statusId === 11 || resp.statusId === 1) {
                    $interval.cancel(ip);
                    Object.assign(self.invoice, resp);
                    $mdDialog.hide(resp);
                    self.saving = false;
                  }
                });
            }, 3000);
          } else {
            $mdDialog.hide(resp);
            self.saving = false;
          }
        }, function(error) {
          if (error.data.status === 409) {
            self.saving = false;
            self.overlapping = true;
          }
        },
      );
    };
  },
]);
