angular.module('CaresApp').controller('AssignToAppointmentDialogController', [
  '$scope',
  '$mdDialog',
  'myOpenAppointments',
  function($scope, $mdDialog, myOpenAppointments) {
    $scope.myOpenAppointments = myOpenAppointments;
    $scope.selected = [];
    $scope.query = {
      limit: 20,
      page: 1,
    };

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = () => $mdDialog.hide($scope.selected[0]);
  },
]);

