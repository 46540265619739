angular.module('CaresApp').controller('ReopenCaseDialogController', [
  '$scope',
  '$mdDialog',
  'closingDate',
  'OcrUtils',
  function($scope, $mdDialog, closingDate, OcrUtils) {
    $scope.closingDate = closingDate;

    $scope.data = {
      reopeningDate: undefined,
    };

    $scope.today = OcrUtils.today;

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = () => $mdDialog.hide($scope.data);
  },
]);
