angular.module('CaresApp').controller('InvoiceExceptionLogController', [
  '$scope',
  '$mdDialog',
  'exceptions',
  'OcrUtils',
  'OcrLookups',
  function($scope, $mdDialog, exceptions, OcrUtils, OcrLookups) {
    $scope.exceptions = exceptions;
    $scope.loaded = true;
    $scope.getUserFullName = OcrUtils.getUserFullName;

    $scope.exceptionStatuses = OcrLookups.dummyList;
    OcrLookups.get('ExceptionStatus').then(
      (exceptionStatuses) => $scope.exceptionStatuses = exceptionStatuses,
    );

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.getUserFullName = function(first, middle, last, suffix) {
      const user = {
        firstName: first,
        middleName: middle,
        lastName: last,
        suffix: suffix,
      }
      return OcrUtils.getUserFullName(user);
    };
  },
]);
