angular.module('CaresApp').controller('SubmitInvoiceDialogController', [
  '$scope',
  '$mdDialog',
  function($scope, $mdDialog) {
    $scope.certified = false;

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = $mdDialog.hide;
  },
]);

