angular.module('CaresApp').controller('AddNoteDialogController', [
  '$mdDialog',
  'OcrUtils',
  function($mdDialog, OcrUtils) {
    const self = this;
    self.today = OcrUtils.today;

    self.$onInit = function() {
      self.adding = angular.isUndefined(self.note)
      if (angular.isUndefined(self.note)) {
        self.note = {
          note: "",
          userId: self.user.id,
          user: self.user,
          activityId: self.appointmentActivityId,
        };

        if (self.activityStartDate) {
          self.note.date = self.activityStartDate;
        } else {
          self.note.date = self.today;
        }
      } else {
        if (!self.note.user) {
          self.note.user = {
            firstName: self.note.firstName,
            lastName: self.note.lastName,
          }
          self.note.user.middleName = self.note.middleName;
          self.note.user.suffix = self.note.suffix;
        }
        self.note = {
          note: self.note.note,
          date: new Date(self.note.date),
          userId: self.note.user.id,
          user: self.note.user,
          activityId: self.appointmentActivityId,
        };
      }
    }

    self.getDialogTitle = function() {
      if (self.adding) {
        return 'Add Note'
      } else if (self.readOnly) {
        return 'View Note'
      }
      return 'Edit Note'
    }

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      if (!self.readOnly) {
        self.note.timestamp = new Date();
        self.note.firstName = self.note.user.firstName;
        self.note.middleName = self.note.user.middleName;
        self.note.lastName = self.note.user.lastName;
        self.note.suffix = self.note.user.suffix;
      }
      $mdDialog.hide(self.note);
    };
  },
]);
