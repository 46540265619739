angular.module('CaresApp').controller('AddPersonaBillingRateDialogController', [
  '$scope',
  '$mdDialog',
  'OcrUtils',
  'persona',
  'billingRates',
  'PersonaBillingRateService',
  '$mdToast',
  function($scope, $mdDialog, OcrUtils, persona, billingRates,
    PersonaBillingRateService, $mdToast) {
    $scope.billingRates = billingRates;

    $scope.billingRate = {
      personaId: persona.id,
    };

    $scope.currentRate = undefined;
    for (const rate of $scope.billingRates) {
      if (!rate.endDate) {
        $scope.currentRate = rate;
        $scope.minDate = new Date(rate.startDate);
        break;
      }
    }

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.updateRate = function(rateValue) {
      if (rateValue === null) {
        $scope.billingRate.billingRateCents = null;
      } else {
        $scope.billingRate.billingRateCents = parseInt(rateValue.replace(
          /\D/g, ""));
      }
    };

    $scope.submit = function() {
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving new billing rate...')
          .position("bottom right"),
      );

      PersonaBillingRateService.save($scope.billingRate).$promise
        .then(function() {
          $mdToast.show(
            $mdToast.simple()
              .textContent('New billing rate saved.')
              .position("bottom right"),
          );

          if ($scope.currentRate) {
            $scope.currentRate.endDate = $scope.billingRate.startDate;
          }
          $scope.billingRates.push($scope.billingRate);

          $mdDialog.hide();
        })
        .catch(function(error) {
          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
    };
  },
]);
