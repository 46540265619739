angular.module('CaresApp').controller('RejectInvoiceDialogController', [
  '$scope',
  '$mdDialog',
  function($scope, $mdDialog) {
    $scope.rejectionNotes = "";

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = () => $mdDialog.hide($scope.rejectionNotes);
  },
]);

