angular.module('CaresApp').controller('OtherApprovalsRequestDialogController', [
  '$scope',
  '$mdDialog',
  'OcrAuth',
  'OcrLookups',
  'appointment',
  function($scope, $mdDialog, OcrAuth, OcrLookups, appointment) {
    $scope.appointment = appointment;

    $scope.requestTypes = OcrLookups.dummyList;
    OcrLookups.get('RequestType').then(
      (requestTypes) => $scope.requestTypes = requestTypes,
    );

    $scope.request = {
      abbreviatedCaseNumber: $scope.appointment.abbreviatedCaseNumber,
      appointmentId: $scope.appointment.id,
      requestStatusId: 1,
      userSubmittedId: OcrAuth.session.user.id,
      timestamp: new Date(),
      judicialDistrictId: $scope.appointment.judicialDistrictId,
      officeId: $scope.appointment.officeId,
    }

    $scope.setPromise = () => self.promise;

    $scope.disableSubmit = () => $scope.otherApprovalsRequestForm.$invalid;

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.submit = function() {
      $scope.request.timestamp = new Date();
      $scope.request.amountCents = parseInt($scope.request.amountCents) * 100;
      $mdDialog.hide($scope.request);
    }
  },
]);
