angular.module('CaresApp').controller('AddContactDialogController', [
  '$scope',
  '$location',
  '$mdDialog',
  '$mdToast',
  'add',
  'contact',
  'ContactService',
  'OcrAuth',
  'OcrLookups',
  function($scope, $location, $mdDialog, $mdToast, add, contact, ContactService, OcrAuth,
    OcrLookups,
  ) {
    $scope.add = add;
    $scope.saving = false;

    $scope.states = OcrLookups.dummyList
    OcrLookups.get('State').then((states) => $scope.states = states);

    if (contact) {
      $scope.contact = angular.copy(contact);
      $scope.isNew = false;
    } else {
      $scope.contact = {
        userId: OcrAuth.session.user.id,
        favorite: false,
        state: "CO",
        invisible: false,
      };
      $scope.isNew = true;
    }

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.submit = function() {
      $scope.saving = true;
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving...')
          .position("bottom right"),
      );

      const continueSave = function() {
        $scope.$parent.promise = null;
        $mdToast.show(
          $mdToast.simple()
            .textContent('Contact saved.')
            .position("bottom right"),
        );
        $mdDialog.hide($scope.contact);
      };

      if ($scope.isNew) {
        // create
        $scope.$parent.promise = ContactService.save($scope.contact).$promise;
        $scope.$parent.promise
          .then(() => continueSave())
          .catch(function(error) {
            if (error && error.data && error.data.status === 401) {
              $location.path("/");
              return;
            }

            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          });
      } else {
        // update
        $scope.$parent.promise = ContactService.update({
          id: $scope.contact.id,
        }, $scope.contact).$promise;

        $scope.$parent.promise
          .then(() => continueSave())
          .catch(function(error) {
            if (error && error.data && error.data.status === 401) {
              $location.path("/");
              return;
            }

            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          });
      }
    };

    $scope.$watch(
      () => angular.isDefined($scope.addcontactform),
      function() {
        $scope.$watch(
          () => $scope.addcontactform.$invalid === true,
          function() {
            angular.forEach($scope.addcontactform.$error, function(field) {
              angular.forEach(field, function(errorField) {
                errorField.$setTouched();
              });
            });
          }, true);
      }, true);
  },
]);
