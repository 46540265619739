angular.module('CaresApp').controller('AddAttachmentDialogController', [
  '$scope',
  '$http',
  '$mdDialog',
  '$mdToast',
  'OcrUtils',
  function($scope, $http, $mdDialog, $mdToast, OcrUtils) {
    const self = this;

    self.today = OcrUtils.today;
    self.chosenFile = "";
    self.uploadComplete = false;
    self.message = "";
    self.submitting = false;
    self.attachment = {
      officeId: 1,
      keywords: "",
      date: self.today,
    };

    self.$onInit = function() {
      if (self.formId && angular.isString(self.formId)) {
        self.formId = self.formId.replace(/^0+/, '');
      }
    }

    self.fileChanged =
      (file, files) => $scope.$apply(function() {
        self.chosenFile = OcrUtils.removeFakePath(file); // string (file name)
        self.files = files; // actual Files objects
      });

    self.hide = $mdDialog.hide;
    self.cancel = $mdDialog.cancel;

    self.submit = function() {
      self.submitting = true;
      const fd = new FormData();
      self.attachment.name = self.chosenFile;
      let url = null;
      if (self.formType === 'appointment') {
        self.attachment.appointmentId = self.formId;
        url = '/api/appointmentAttachments';
      } else if (self.formType === 'activity') {
        self.attachment.activityId = self.formId;
        self.attachment.appointmentId = self.appointmentId;
        self.attachment.officeId = self.officeId;
        url = '/api/activityAttachments';
      } else if (self.formType === 'approval') {
        self.attachment.otherExpenseRequestId = self.formId;
        self.attachment.appointmentId = self.appointmentId;
        url = '/api/approvalAttachments';
      } else if (self.formType === 'exception') {
        self.attachment.exceptionLogId = self.formId;
        url = '/api/exceptionAttachments';
      } else {
        console.error(`Unknown form type: ${self.formType}`);
        return;
      }
      fd.append('json_data', angular.toJson(self.attachment));
      fd.append('file', self.files[0], self.chosenFile);
      const httpReq = {
        url: url,
        method: 'POST',
        transformRequest: angular.identity,
        headers: {
          'Content-Type': undefined,
        },
        data: fd,
        timeout: 60000,
      };
      $http(httpReq).then(
        function() {
          $mdDialog.hide(self.attachment);
          self.submitting = false;
        },
      ).catch(function(error) {
        if (error && error.status === -1) {
          $mdDialog.show(
            $mdDialog.alert()
              .title('Upload Error')
              .textContent("Unable to upload attachment. The file may be too large.")
              .multiple(true)
              .ok('Close')
              .targetEvent(event),
          );
          $mdDialog.hide(undefined);
          self.submitting = false;
          return;
        }
        if (error && error.data && error.data.status === 401) {
          $mdDialog.hide(undefined);
          self.submitting = false;
        }
        if (error && error.data) {
          $mdToast.show(
            $mdToast.simple()
              .textContent(error.data.reason)
              .position("bottom right"),
          );
          $mdDialog.hide(undefined);
          self.submitting = false;
        }
      });
    };
  },
]);
