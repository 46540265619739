angular.module('CaresApp').controller('ChangeCaseTypeBillingAllocationController', [
  '$scope',
  '$location',
  '$mdDialog',
  'caseTypeId',
  'CaseTypeBillingAllocationService',
  '$mdToast',
  function($scope, $location, $mdDialog, caseTypeId, CaseTypeBillingAllocationService, $mdToast) {
    $scope.tempAllocation = undefined;
    $scope.billingAllocation = {
      caseTypeId: caseTypeId,
      startDate: new Date(),
    };

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.submit = function() {
      $scope.billingAllocation.startDate.setHours(0);
      $scope.billingAllocation.startDate.setMinutes(0);
      $scope.billingAllocation.startDate.setSeconds(0);
      $scope.billingAllocation.startDate.setMilliseconds(0);
      $mdToast.show(
        $mdToast.simple()
          .textContent('Saving...')
          .position("bottom right"),
      );

      const continueSave = function() {
        $scope.$parent.promise = null;
        $mdToast.show(
          $mdToast.simple()
            .textContent('Billing allocation saved.')
            .position("bottom right"),
        ).then(function() {
          $mdDialog.hide($scope.billingAllocation);
        });
      };


      $scope.$parent.promise = CaseTypeBillingAllocationService.save(
        $scope.billingAllocation).$promise;
      $scope.$parent.promise
        .then(() => continueSave())
        .catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            $location.path("/");
            return;
          }

          if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
    };

    $scope.updateAllocation = function(tempAll) {
      if (tempAll === null) {
        $scope.billingAllocation.apptBillingAlloCents = null;
      } else {
        $scope.billingAllocation.apptBillingAlloCents = parseInt(tempAll.replace(/\D/g, ""));
      }
    }
  },
]);
