angular.module('CaresApp').controller('AddAdditionalUserDialogController', [
  '$scope',
  '$mdDialog',
  'add',
  'officeUsers',
  'user',
  'existingUsers',
  'OcrUtils',
  'appointmentId',
  'attorneyUserId',
  'earliestDate',
  function($scope, $mdDialog, add, officeUsers, user, existingUsers,
    OcrUtils, appointmentId, attorneyUserId, earliestDate) {

    $scope.add = add;
    $scope.officeUsers = [];
    $scope.appointmentId = appointmentId;
    $scope.attorneyUserId = attorneyUserId;
    $scope.earliestDate = earliestDate;

    $scope.getUserFullName = OcrUtils.getUserFullName;

    function existingAndOpen(userId) {
      if (existingUsers) {
        for (let c = 0; c < existingUsers.length; c++) {
          if (existingUsers[c].userId === userId) {
            return true;
          }
        }
      }
      return false;
    }

    for (let c = 0; c < officeUsers.length; c++) {
      if (!existingAndOpen(officeUsers[c].id)
        || (user && user.userId === officeUsers[c].id)
      ) {
        if (officeUsers[c].id !== $scope.attorneyUserId) {
          $scope.officeUsers.push(officeUsers[c]);
        }
      }
    }

    $scope.getUserById = function(id) {
      for (let c = 0; c < $scope.officeUsers.length; c++) {
        if (id === $scope.officeUsers[c].id) {
          return $scope.officeUsers[c];
        }
      }
      return undefined;
    };

    if (user) {
      $scope.user = angular.copy(user);
      $scope.user.user = $scope.getUserById($scope.user.userId);
      $scope.user.personaId = $scope.user.user.personaId;
      $scope.user.userName = $scope.getUserFullName($scope.user.user);
      $scope.user.userType = $scope.user.user.personaName;
      $scope.user.startDate = new Date($scope.user.startDate);
      if ($scope.user.endDate) {
        $scope.user.endDate = new Date($scope.user.endDate);
      }
    } else {
      $scope.user = {
        userId: undefined,
      };
    }
    $scope.user.appointmentId = appointmentId;

    $scope.today = OcrUtils.today


    $scope.userChanged = function() {
      const aUser = $scope.getUserById($scope.user.userId);
      if (!aUser) {
        return;
      }
      $scope.user.firstName = aUser.firstName;
      $scope.user.middleName = aUser.middleName;
      $scope.user.lastName = aUser.lastName;
      $scope.user.suffix = aUser.suffix;
      $scope.user.personaId = aUser.personaId;
      $scope.user.userType = aUser.personaName;
    };

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = () => $mdDialog.hide($scope.user);

    $scope.$watch(
      () => angular.isDefined($scope.adduserform),
      function() {
        $scope.$watch(
          () => $scope.adduserform.$invalid === true,
          function() {
            angular.forEach($scope.adduserform.$error, function(field) {
              $scope.$watch(
                () => field.length,
                function() {
                  angular.forEach(field, function(errorField) {
                    errorField.$setTouched();
                  });
                }
              )
            });
          }, true);
      }, true);
  },
]);
