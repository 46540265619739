angular.module('CaresApp').controller('CloseCaseDialogController', [
  '$scope',
  '$mdDialog',
  'OcrUtils',
  function($scope, $mdDialog, OcrUtils) {
    $scope.data = {
      closingDate: undefined,
    };

    $scope.today = OcrUtils.today;

    $scope.hide = $mdDialog.hide;
    $scope.cancel = $mdDialog.cancel;

    $scope.submit = () => $mdDialog.hide($scope.data);
  },
]);
